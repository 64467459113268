import {
  Autocomplete,
  Box,
  Button,
  Card,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ModalProduct = ({
  isOpen,
  setisOpen,
  activeFilter,
  dataLabel,
  SayurBoxProduct,
  filterProduct,
  handleProductChange,
  handleChangeText,
  item,
  activeModal,
  setactiveModal,
  deletedRow,
  setDeletedRow,
  AstroProduct,
  handleMomChange,
}) => {
  const [product, setproduct] = useState(null);
  const handleChange = (data, value) => {
    setproduct({ id: data, value: value });
  };
  const handleSubmit = () => {
    handleProductChange(product.id, product.value);
    handleMomChange(product.id, product.value);
    setisOpen(false);
    setactiveModal(null);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setisOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          alignItems: "center",
          justifyContent: "center",
          width: "30vw",
          height: "20vw",

          backgroundColor: "white",
        }}
      >
        {/* component to be printed */}
        <Box display={"flex"} p={2} flexDirection={"column"} gap={2}>
          <Autocomplete
            disablePortal
            // defaultValue={{
            //   value: item.product !== null ? item.product.value : "",
            //   label: item.product !== null ? item.product.label : "",
            // }}

            sx={{ width: 300 }}
            options={
              activeFilter === "Sayur Box"
                ? SayurBoxProduct
                : activeFilter === "Astro"
                ? AstroProduct
                : filterProduct
            }
            onChange={(event, value) => handleChange(item.id, value, event)}
            renderInput={(params) => (
              <TextField
                onChange={handleChangeText}
                {...params}
                label="Produk"
              />
            )}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ ml: "auto" }}
            onClick={handleSubmit}
          >
            Simpan
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalProduct;
